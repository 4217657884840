export function formatLatency(latency: number) {
    if (latency <= 0) {
        return '-';
    }
    if (latency > 60 * 1000 * 60 * 24) {
        return (Math.round(latency / 1000 / 3600 / 24 * 100) / 100) + " days"
    }
    if (latency > 60 * 1000 * 60 * 3) {
        return Math.ceil(latency / 1000 / 3600) + " hrs"
    }
    if (latency > 60 * 1000 * 10) {
        return Math.ceil(latency / 1000 / 360) + " min"
    }

    if (latency > 60 * 1000) {
        return Math.ceil(latency / 1000) + " s"
    }

    if (latency < 1) {
        return "< 1 ms"
    }

    return toRoundedMillisecondString(latency)
}

export function toRoundedMillisecondString(num: number | undefined) {
    if (!num && num !== 0) {
        return "";
    }
    if (num < 1) {
        return "<1ms"
    }
    return Math.round(num) + "ms";
}

export function fullClassToSimpleName(name: string) {
    if (!name || name.length < 1) {
        return ""
    }
    const parts = name.split(".")
    return parts[parts.length - 1]
}

export const bytesToText = (value: number) => {
    if (value < 1024) {
        return `${value} B`
    }
    const kb = value / 1024
    if (kb < 1024) {
        return `${kb.toFixed(2)} KB`
    }
    const mb = kb / 1024
    if (mb < 1024) {
        return `${mb.toFixed(2)} MB`
    }
    const gb = mb / 1024
    return `${gb.toFixed(2)} GB`
}

export const userRoleToText = (role: string) => {
    switch (role) {
        case 'VIEWER':
            return 'Viewer'
        case 'USER':
            return 'User'
        case 'ADMIN':
            return 'Admin'
        case 'OWNER':
            return 'Owner'
        default:
            return 'Unknown'
    }
}

const allUserRoles = [
    {title: 'Viewer', value: 'VIEWER'},
    {title: 'Developer', value: 'USER'},
    {title: 'Admin', value: 'ADMIN'},
]
// Utility function to convert snake_case to Title Case, mainly for plan names
export const formatToTitleCase = (unformattedName: string) => {
    return unformattedName?.toLowerCase()?.split('_')?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
        ?.join(' ');
}